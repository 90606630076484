import RestApi, {
    commonServiceBaseUrl,
    agriResearchServiceBaseUrl
} from '../config/api_config'

export default {
  computed: {
  },
  watch: {
  },
  created () {
    this.setOrgAndOrgComponentList()
    this.loadDropdownCommonConfig()
    // const hasDropdownLoadedAgriService = this.$store.state.agriObj.hasDropdownLoaded
    // if (!hasDropdownLoadedAgriService || window.performance) {
    //   this.loadDropdownCommonConfig()
    // }
  },
  methods: {
    setOrgAndOrgComponentList () {
        RestApi.getData(commonServiceBaseUrl, 'common/org-and-org-component-list').then(response => {
          if (response.success === true) {
            const tmpData = response.data.orgList.filter(item => {
                if (item.status !== 1) {
                    const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
                    return Object.assign({}, item, tmp)
                }
            })
            this.mixinSetOrganizationProfileList(3, response.data.orgComponentList, tmpData)
          }
        })
    },
    mixinSetOrganizationProfileList (mixinComponentId, orgComponentList, tmpData) {
        const orgComponents = orgComponentList.filter(item => item.component_id === mixinComponentId)
        const orgCompList = orgComponents.map(item => {
            const orgObj = tmpData.find(org => org.value === item.org_id)
            return this.$i18n.locale === 'en' ? Object.assign({}, orgObj, { text: orgObj.text_en }) : Object.assign({}, orgObj, { text: orgObj.text_bn })
        })
        this.$store.commit('Portal/mutateAgriResearchDropdown', {
            hasDropdownLoaded: true,
            organizationProfileList: orgCompList
        })
    },
    loadDropdownCommonConfig () {
      RestApi.getData(agriResearchServiceBaseUrl, '/portal-agri-research-dropdowns', null).then(response => {
        if (response.success) {
            this.$store.commit('Portal/mutateAgriResearchDropdown', {
                hasDropdownLoaded: true,
                publicationTypeList: response.data.publicationTypeList,
                publicationTitleList: response.data.publicationTitleList,
                thematicAreaList: response.data.thematicAreaList,
                publicationContentAreaList: response.data.publicationContentAreaList,
                sectorList: response.data.sectorList,
                subSectorList: response.data.subSectorList
            })
            this.$store.dispatch('Portal/localizeAgriResearchDropdown', { value: this.$i18n.locale })
        }
      })
    }
  }
}
