import RestApi, { commonServiceBaseUrl, seedFertilizerServiceBaseUrl } from '../config/api_config'

export default {
  created () {
    this.loadSeedsFertilizerCommonDropdown()
  },
  methods: {
    loadSeedsFertilizerCommonDropdown () {
      RestApi.getData(seedFertilizerServiceBaseUrl, 'portal-common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('Portal/mutateSeedsFertilizerDropdown', {
            CropTypeList: response.data.CropTypeList,
            CropNameList: response.data.CropNameList,
            VarietyList: response.data.VarietyList,
            measurementUnitList: response.data.measurementUnitList,
            productionSeasonList: response.data.productionSeasonList,
            organizationList: response.data.organizationList,
            gpOrganizationsList: response.data.gpOrganizationList
          })
          this.$store.dispatch('Portal/localizeSeedsFertilizerDropdown', { value: this.$i18n.locale })
        }
      })
    },
    loadCommonConfig () {
      RestApi.getData(commonServiceBaseUrl, 'external-common-config2-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('ExternalIncentiveFarmer/mutateDropdownCommonConfig', {
            bankList: response.data.bankList,
            branchList: response.data.branchList,
            cityCorporationList: response.data.cityCorporationList,
            pauroshobaList: response.data.pauroshobaList,
            wardList: response.data.wardList
          })
          this.$store.dispatch('ExternalIncentiveFarmer/localizeDropdownCommonConfig', { value: this.$i18n.locale })
        }
      })
    }
  }
}
